$(function() {
    'use strict';

    $('.calculatorForm').submit(function (e) {
        e.preventDefault();

        if ('' == $('#fee').val()) {
            $('input#fee ~ p.text-danger').html($('input#fee').data('validation-message'));
        } else {
            $('input#fee ~ p.text-danger').html('');
        }

        if ('' == $('#hoursPerWeek').val()) {
            $('input#hoursPerWeek ~ p.text-danger').html($('input#hoursPerWeek').data('validation-message'));
        } else {
            $('input#hoursPerWeek ~ p.text-danger').html('');
        }

        if ('' == $('#travelingExpenses').val()) {
            $('input#travelingExpenses ~ p.text-danger').html($('input#travelingExpenses').data('validation-message'));
        } else {
            $('input#travelingExpenses ~ p.text-danger').html('');
        }

        $.ajax({
            url:         '/application/themes/cleardesign/mail/calculator.php',
            type:        'POST',
            data:        new FormData(this),
            contentType: false,
            cache:       false,
            processData: false,
            /**
             * Gibt die Vergleichswerte aus
             *
             * @param bool data.state
             * @param string data.message
             * @param object data.salary
             */
            success: function (data) {
                if (true == data.state) {
                    $('.comparision').show();
                    var salary = data.salary.yearUnformat;

                    $('.calculator-message').hide();
                    $('.calculator-results').show();

                    $('#salaryPerMonth').val(data.salary.month);
                    $('#salaryPerYear').val(data.salary.year);
                    $('.calculator-bg').removeClass('calculator-bg');

                    // Jobs vergleichen und anzeigen wo der Freelancer steht
                    var jobbString1 = '';
                    var jobbString2 = '';
                    $('table.example-fees .jobs').each(function() {
                        var from = $(this).attr('data-from');
                        var to   = $(this).attr('data-to');
                        if (salary >= from && salary <= to) {
                            $(this).addClass('rot-braun-strong warning');
                            $(this).find('td.result').html('<span class="glyphicon glyphicon-hand-right" aria-hidden="true"></span>');
                            jobbString1 += $(this).find('td.job').html()+' + ';
                        } else {
                            $(this).removeClass('rot-braun-strong warning');
                            $(this).find('td.result').html('');
                        }
                    });

                    $('table.example2-fees .jobs').each(function() {
                        var from = $(this).attr('data-from');
                        var to   = $(this).attr('data-to');
                        if (salary >= from && salary <= to) {
                            $(this).addClass('rot-braun-strong warning');
                            $(this).find('td.result').html('<span class="glyphicon glyphicon-hand-right" aria-hidden="true"></span>');
                            jobbString2 += $(this).find('td.job').html()+' + ';
                        } else {
                            $(this).removeClass('rot-braun-strong warning');
                            $(this).find('td.result').html('');
                        }
                    });

                    $('#consultationJob').html(jobbString1.substr(0, jobbString1.length-2));
                    $('#guideJob').html(jobbString2.substr(0, jobbString2.length-2));
                } else {
                    $('.comparision').hide();
                    $('.calculator-results').hide();
                    $('.calculator-message').html(data.message).show();
                }
            },
            error: function () {
                $('.calculator-message').show().html('Serverfehler!');
            },
            dataType: 'json'
        });

    });
});