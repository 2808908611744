$(function($) {
    $('.lazy').Lazy({
        scrollDirection: 'both',
        effect: 'fadeIn',
        visibleOnly: true,
        delay: 200,
        onError: function(element) {
            console.log('error loading ' + element.data('src'));
        }
    });
});