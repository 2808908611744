jQuery(function($) {

    /* Modalbox Datenschutz*/
    $('body').append('<div class="modal fade" id="infoDatenschutz" tabindex="-1" role="dialog">\
        <style>ul.modal-list {list-style: circle;} .modal-list li {margin-left: -20px;}</style>\
        <div class="modal-dialog">\
            <div class="modal-content">\
                <div class="modal-header">\
                    <button type="button" class="close" data-dismiss="modal">&times;</button>\
                    <h4 class="modal-title">Datenschutzerklärung</h4>\
                </div>\
                <div class="modal-body">\
                        <h3>Name und Kontaktdaten des für die Verarbeitung Verantwortlichen und des Datenschutzbeauftragten</h3>\
                        <p>Verantwortlicher: Robin Vij (ROV), Ammerweg 19, 56564 Neuwied<br />\
                        Datenschutzbeauftragter: Robin Vij<br />\
                        Website: <a href="https://www.bigdreams.eu">bigdreams.eu</a></p>\
                        <h3>Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</h3>\
                        <h4>a) Beim Besuch der Website</h4>\
                        <p>Beim Aufrufen unserer Website werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:</p>\
                        <ul class="modal-list">\
                        <li>IP-Adresse des anfragenden Rechners,</li>\
                        <li>Datum und Uhrzeit des Zugriffs,</li>\
                        <li>Name und URL der abgerufenen Datei,</li>\
                        <li>Website, von der aus der Zugriff erfolgt (Referrer-URL),</li>\
                        <li>verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.</li>\
                        </ul>\
                        <p>Die genannten Daten werden durch uns zu Zwecken der Gewährleistung eines reibungslosen Verbindungsaufbaus der Website, der Gewährleistung einer komfortablen Nutzung unserer Website, der Auswertung der Systemsicherheit und -stabilität sowie zu weiteren administrativen Zwecken verarbeitet.\
                        Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.\
                        Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie Analysedienste (siehe unten) ein.</p>\
                        <h4>b) Bei Nutzung unseres Kontaktformulars</h4>\
                        <p>Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über ein auf der Website bereitgestelltes Formular Kontakt aufzunehmen. Dabei ist die Angabe Ihres Namens, ihrer Telefonnummer und einer gültigen E-Mail-Adresse erforderlich, damit wir wissen, von wem die Anfrage stammt und um diese beantworten zu können. Weitere Angaben können freiwillig getätigt werden.\
                        Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig erteilten Einwilligung.\
                        Die für die Benutzung des Kontaktformulars von uns erhobenen personenbezogenen Daten werden nach Erledigung der von Ihnen gestellten Anfrage automatisch gelöscht.</p>\
                        <h4>c) Bei Online-Bewerbungen</h4>\
                        <p>Im Rahmen der über die Jobsuche angegeben Jobvorschläge können Sie mit uns ebenfalls über ein auf der Website bereitgestelltes Formular Kontakt aufzunehmen. Dabei ist die Angabe Ihres Namens, ihrer Telefonnummer und einer gültigen E-Mail-Adresse erforderlich, damit wir wissen, von wem die Anfrage stammt und um diese beantworten zu können. Weitere Angaben können freiwillig getätigt werden. Unter Umständen können Sie auch Anlagen beifügen (z.B. Lebenslauf).\
                        Die Datenverarbeitung zum Zwecke der Bewerberkontaktaufnahme erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig erteilten Einwilligung.\
                        Die für die Benutzung des Kontaktformulars von uns erhobenen personenbezogenen Daten werden nach Erledigung der von Ihnen gestellten Anfrage automatisch gelöscht, sofern Sie nicht im Rahmen des Kontakts einer weitergehenden Speicherung für spätere eventuell passende Jobvorschläge bei uns gespeichert lassen möchten.</p>\
                        <h3>Weitergabe von Daten</h3>\
                        <p>Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</p>\
                        <ul class="modal-list">\
                        <li>Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,</li>\
                        <li>die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,</li>\
                        <li>für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie</li>\
                        <li>dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.</li>\
                        </ul>\
                        <h3>Cookies</h3>\
                        <p>Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.\
                        In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten.\
                        Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. So setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Website bereits besucht haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht.\
                        Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.\
                        Zum anderen setzten wir Cookies ein, um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten. Diese Cookies ermöglichen es uns, bei einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gelöscht.\
                        Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.\
                        Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neues Cookie angelegt wird. Sie können Ihren Browser so einstellen, dass Sie vor Aussendung der Cookies bei entsprechender Browsereinstellung auf die Verwendung von Cookies hingewiesen werden oder dass er Cookies zurückweist. Um die Cookiebehandlung generell zu verändern, konsultieren Sie bitte die Hilfe Ihres Browsers. In der Regel finden sich diese Einstellungen unter Extras -> Einstellungen Datenschutz (Firefox) oder unter Extras -> Internetoptionen -> Datenschutz (Internet Explorer). Bei der Nichtannahme von Cookies kann die Funktionalität der ROV Webseite allerdings eingeschränkt sein. Die meisten der von ROV verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende der Browser-Sitzung wieder von Ihrer Festplatte gelöscht.</p>\
                        <p><strong>Cookies - welche, wie lange, warum?</strong></p>\
                        <p><strong>F ür die Nutzung dieser Webseite werden Cookies gespeichert. Hierbei unterscheiden wir, ob die Cookies essenziellen, analytischen und/oder Marketingzwecken dienen. Beim Aufruf dieser Webseite wird ein Cookie-Banner angezeigt. Hier k önnen Sie individuelle Cookie-Einstellungen vornehmen oder die Ausspielung jener Cookies ablehnen, die nicht zwingend f ür den Betrieb der Seite erforderlich sind. Ferner erhalten Sie die Option, das Ausspielen von Cookies grunds ätzlich zu gestatten:</strong></p>\
                        <p><strong>Typ:  E S S E N Z I E L L</strong></p>\
                        <p><strong>&raquo; Die Kategorie &quot;Essenziell&quot; bezeichnet jene Cookie-Art, die zwingend ben ötigt werden, damit diese Webseite auch unter Ber ücksichtigung von Cookie-Einstellungen ordnungsgem ä&szlig; funktioniert. Das sind unsere elementaren 1st-Party Cookies: CONCRETE5 &amp; _essential &amp; _marketing &amp; _analytics &amp; CONCRETE5_LOGIN</strong></p>\
                        <p><strong>Typ:  A N A L Y S E</strong></p>\
                        <p><strong>&raquo; Die Kategorie &quot;Analyse&quot; bezeichnet jene Cookie-Art, die f ür statistische Zwecke, die Ermittlung von Zugriffszahlen und das Benutzerverhalten - auch plattform übergreifend - ben ötigt werden.</strong></p>\
                        <p><strong>Die aktuelle Version von Google Analytics (analytics.js) setzt potenziell folgende Cookies: _ga, _gid, _gat, _dc_gtm_xxx, _gat_gtag_xxx, _gac_xxx, IDE. Abh ängig von den entsprechenden Konfiguration werden nicht zwingend alle der genannten Cookies ausgespielt. Die alte Version von Google Analytics (ga.js) setzt andere Cookies, diese verwenden wir aktuell nicht. So verwenden wir z.B. nicht den _dc_gtm_xxx Cookie. Der Google Tag Manager selbst setzt keine Cookies. Denn Cookies werden nur von den Tools gesetzt (z.B. Google Analytics, Google Ads Conversion Tracking), die lediglich  über den Google Tag Manager eingebunden werden. Das Google Ads Conversion Tracking setzt das IDE-Cookie und ggf. auch test_cookie. Grunds ätzlich werden die gleichen Cookies  übrigens auch gesetzt, wenn Google Ads Remarketing Anwendung findet. Das sind die Cookies im Detail:</strong></p>\
                        <p><strong>_ga | Verwendung = Ja<br />\
                            Enth ält eine zufallsgenerierte User-ID. Anhand dieser ID kann Google Analytics wiederkehrende User auf dieser Website wiedererkennen und die Daten von fr üheren Besuchen zusammenf ühren.<br />\
                        Speicherdauer: 2 Jahre (1st Party)</strong></p>\
                        <p><strong>_gid | Verwendung = Ja<br />\
                            Enth ält eine zufallsgenerierte User-ID. Anhand dieser ID kann Google Analytics wiederkehrende User auf dieser Website wiedererkennen und die Daten von fr üheren Besuchen zusammenf ühren.<br />\
                        Speicherdauer: 24 Stunden (1st Party)</strong></p>\
                        <p><strong>_gat | Verwendung = Ja<br />\
                            Bestimmte Daten werden nur maximal einmal pro Minute an Google Analytics gesendet. Das Cookie hat eine Lebensdauer von einer Minute. Solange es gesetzt ist, werden bestimmte Daten übertragungen unterbunden.<br />\
                        Speicherdauer: 1 Minute (1st Party)</strong></p>\
                        <p><strong>_gat_gtag_xxx | Verwendung = Ja<br />\
                            Bestimmte Daten werden nur maximal einmal pro Minute an Google Analytics gesendet. Das Cookie hat eine Lebensdauer von einer Minute. Solange es gesetzt ist, werden bestimmte Daten übertragungen unterbunden.<br />\
                        Speicherdauer: 1 Minute (1st Party)</strong></p>\
                        <p><strong>_gac_xxx | Verwendung = Ja<br />\
                            Dieses Cookie wird gesetzt, wenn ein User  über einen Klick auf eine Google Werbeanzeige auf die Website gelangt. Es enth ält Informationen dar über, welche Werbeanzeige geklickt wurde, sodass erzielte Erfolge wie z.B. Bestellungen oder Kontaktanfragen der Anzeige zugewiesen werden k önnen.<br />\
                        Speicherdauer: 90 Tage (1st Party)</strong></p>\
                        <p><strong>IDE | Verwendung = Nein<br />\
                            Enth ält eine zufallsgenerierte User-ID. Anhand dieser ID kann Google den User  über verschiedene Websites domain übergreifend wiedererkennen und personalisierte Werbung ausspielen.<br />\
                        Speicherdauer: 1 Jahr (3rd Party / doubleclick.net)</strong></p>\
                        <p><strong>test_cookie | Verwendung = Nein<br />\
                            Wird testweise gesetzt, um zu pr üfen, ob der Browser das Setzen von Cookies erlaubt. Enth ält keine Identifikationsmerkmale.<br />\
                        Speicherdauer: 15 Min. (3rd Party / doubleclick.net)</strong></p>\
                        <p><strong>Hinweis: F ür diese Webseite wird der IP-Tracking-Code von Google aus Datenschutzgr ünden ebenfalls anonymisiert!</strong></p>\
                        <p><strong>Typ:  M A R K E T I N G</strong></p>\
                        <p><strong>&raquo; Die Kategorie &quot;Marketing&quot; bezeichnet jene Cookie-Art, die z.B. f ür personalisierte Informationen, Anzeigen und Kampagnen eingesetzt werden.</strong></p>\
                        <p><strong>Cookies von Google werden nur von den entsprechenden Tools gesetzt (z.B. Google Analytics, Google Ads Conversion Tracking), die  über den Google Tag Manager eingebunden werden. Das Google Ads Conversion Tracking setzt das Cookie IDE und eventuell das Cookie test_cookie. Grunds ätzlich werden die gleichen Cookies auch dann gesetzt, wenn Google Ads Remarketing Anwendung findet. Nachfolgend werden die relevanten Google &amp; Facebook Cookies bezeichnet:</strong></p>\
                        <p><strong>IDE | Speicherdauer: Verwendung = Nein<br />\
                            Enth ält eine zufallsgenerierte User-ID. Anhand dieser ID kann Google den User  über verschiedene Websites domain übergreifend wiedererkennen und personalisierte Werbung ausspielen.<br />\
                        1 Jahr (3rd Party / doubleclick.net)</strong></p>\
                        <p><strong>test_cookie | Verwendung = Nein<br />\
                            Wird ggf. testweise gesetzt, um zu pr üfen, ob der Browser das Setzen von Cookies gestattet. Dieser Google Cookie enth ält keine Identifikationsmerkmale.<br />\
                        Speicherdauer: 15 Min. (3rd Party / doubleclick.net)</strong></p>\
                        <p><strong>_fbp | Verwendung = Nein<br />\
                            Wird von Facebook genutzt, um eine Reihe von Marketinginhalte anzuzeigen, zum Beispiel auch Echtzeitgebote Dritter.<br />\
                        Speicherdauer: 3 Monate</strong></p>\
                        <p><strong>fr | Verwendung = Nein<br />\
                            Wird von Facebook genutzt, um eine Reihe von Marketinginhalte anzuzeigen, zum Beispiel auch Echtzeitgebote Dritter.<br />\
                        Speicherdauer: 3 Monate</strong></p>\
                        <p><strong>tr | Verwendung = Nein<br />\
                            Wird von Facebook genutzt, um eine Reihe von Marketinginhalte anzuzeigen, zum Beispiel auch Echtzeitgebote Dritter.<br />\
                        Speicherdauer: Session (Nur solange wie Besucher auf unserer Webseite sind)</strong></p>\
                        <h3>Analyse-Tools</h3>\
                        <p>Die im Folgenden aufgeführten und von uns eingesetzten Tracking-Maßnahmen werden auf Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO durchgeführt. Mit den zum Einsatz kommenden Tracking-Maßnahmen wollen wir eine bedarfsgerechte Gestaltung und die fortlaufende Optimierung unserer Webseite sicherstellen. Zum anderen setzen wir die Tracking-Maßnahmen ein, um die Nutzung unserer Webseite statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten. Diese Interessen sind als berechtigt im Sinne der vorgenannten Vorschrift anzusehen.\
                        Die jeweiligen Datenverarbeitungszwecke und Datenkategorien sind aus den entsprechenden Tracking-Tools zu entnehmen.</p>\
                        <h4>a) Facebook-Plugin</h4>\
                        <p>Wir nutzen gegebenenfalls Plug-Ins des sozialen Netzwerks <a href="https://www.facebook.com">"facebook.com"</a> , betrieben von Facebook Inc, 1601 S. California Ave, Palo Alto, CA 94304, USA ("Facebook"). Sie können die Facebook-Plug-Ins am Facebook-Logo und dem "Gefällt mir"-Button erkennen.\
                        Wenn Sie eine Webseite aufrufen, die ein solches Plug-In enthält, stellt Ihr Browser eine direkte Verbindung zum Facebook Server her. Facebook übermittelt sodann den Inhalt des Plug-Ins an Ihren Browser, der das Plug-In wiederum in die Webseite einbindet. Facebook erhält auf diese Weise die Information, dass ein Nutzer mit einer bestimmten IP-Adresse, nämlich der IP-Adresse, die Ihrem Internetzugang zur Zeit der Übertragung zugewiesen ist, die entsprechende Webseite besucht hat. Sofern Sie zur gleichen Zeit in ihren Facebook-Account eingeloggt sind, kann Facebook Ihren Besuch bei uns Ihrem Facebook-Account zuordnen, auch wenn Sie den "Gefällt mir"-Button nicht betätigen. Wenn Sie das Plug-In durch Anklicken des "Gefällt mir"-Button nutzen, wird dies von Ihrem Browser ebenfalls an Facebook übermittelt und dort gespeichert. Sollten Sie nicht wünschen, dass Facebook über unsere Webseite Daten sammelt und Ihrem Facebook-Account zuordnet, loggen Sie sich bitte bei Facebook aus, bevor Sie unsere Webseite besuchen.\
                        Weitere Informationen über den Zweck und den Umfang der Erhebung, Speicherung und Nutzung dieser Daten durch Facebook sowie hinsichtlich Ihrer diesbezüglichen Rechte und der möglichen Einstellungen Ihres Facebook-Accounts, um Ihre privaten Daten zu schützen, finden Sie in der Facebook-Datenschutzerklärung.</p>\
                        <h4>b) Google+ Plugin</h4>\
                        <p>Wir nutzen gegebenenfalls Plug-Ins des sozialen Netzwerks von Google (Google+), welche von der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“) in den USA betrieben wird. Sie können die Plug-Ins an der farbigen Schrift „Google+“ auf unserer Seite erkennen. Wenn Sie unsere Webseite aufrufen, die ein solches Plug-In enthält, stellt Ihr Browser eine direkte Verbindung zum Google Server her. Der Inhalt des Plug-In wird von Google direkt an Ihren Browser übermittelt und von diesem in die Webseite eingebunden. Wir haben keinen Einfluss auf den Umfang der erhobenen Daten die Google erhebt. Google erhält die Information, dass ein Nutzer mit seiner IP-Adresse unsere Webseite besucht hat. Wenn sie selbst bei Google+ eingeloggt sind und nicht möchten, dass Google Daten über Ihren Besuch speichert, müssen Sie sich vor Besuch unserer Webseite in Ihrem Google Account ausloggen. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Google, sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte Googles Datenschutzhinweisen zu Google+.</p>\
                        <h4>c) Twitter-Plugin</h4>\
                        <p>Unsere Seite verwendet zum Teil das Plug-In des Netzwerks Twitter (<a href="https://twitter.com/about/resources/buttons">https://twitter.com/about/resources/buttons</a>). Das Plug-In wird durch die Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA 94107, USA angeboten. Das Plug-In ist durch die hellblaue Schrift „twitter“ auf weißem Grund auf unserer Webseite zu erkennen. Durch das Twitter Plug-In ist es möglich, Beiträge von uns auf Twitter zu teilen oder uns bei Twitter zu folgen. Wenn Sie unsere Webseite aufrufen, die ein solches Plug-In enthält, stellt Ihr Browser eine direkte Verbindung zum Twitter Server her. Der Inhalt des Plug-In wird von Twitter direkt an Ihren Browser übermittelt. Wir haben keinen Einfluss auf den Umfang der Daten, die Twitter erhebt. Nach unserem Kenntnisstand werden die IP-Adresse des Nutzers, sowie die URL der jeweiligen Webseite beim Klicken auf das Plug-In an Twitter übermittelt, dabei jedoch nur zum Zwecke der Darstellung des Plug-Ins genutzt. Weitere Informationen finden Sie in der Datenschutzerklärung von Twitter</p>\
                        <h4>d) Xing-Plugin</h4>\
                        <p>Unsere Seite verwendet teilweise auch den Share-Button des Netzwerks XING (<a href="https://www.xing.com/app/share?op=button_builder">https://www.xing.com/app/share?op=button_builder</a>) Das Plug-In wird durch die XING AG, Gänsemarkt 43, 20354 Hamburg („XING“) angeboten. Beim Aufruf unserer Webseite wird über Ihren Browser kurzfristig eine Verbindung zu den Servern von XING aufgebaut. XING speichert keine personenbezogenen Informationen über Nutzer, die unserer Webseite aufgerufen haben. Die IP-Adresse wird ebenfalls nicht von XING gespeichert. Es findet auch keine Auswertung Ihres Nutzungsverhaltens über die Verwendung von Cookies im Zusammenhang mit dem XING Share-Button statt. Eine zusammenfassende Darstellung der Funktionalitäten des Share-Buttons erhalten Sie unter: https://www.xing.com/app/share?op=data_protection. Eine allgemeine Beschreibung der Datenverarbeitung erhalten Sie in der XING Datenschutzerklärung.</p>\
                        <p> </p>\
                        <h3>Analyse-Tools</h3>\
                        <p>Die im Folgenden aufgeführten und von uns eingesetzten Tracking-Maßnahmen werden auf Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO durchgeführt. Mit den zum Einsatz kommenden Tracking-Maßnahmen wollen wir eine bedarfsgerechte Gestaltung und die fortlaufende Optimierung unserer Webseite sicherstellen. Zum anderen setzen wir die Tracking-Maßnahmen ein, um die Nutzung unserer Webseite statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten. Diese Interessen sind als berechtigt im Sinne der vorgenannten Vorschrift anzusehen.\
                        Die jeweiligen Datenverarbeitungszwecke und Datenkategorien sind aus den entsprechenden Tracking-Tools zu entnehmen.</p>\
                        <h4>a) Facebook-Plugin</h4>\
                        <p>Wir nutzen gegebenenfalls Plug-Ins des sozialen Netzwerks <a href="https://www.facebook.com">"facebook.com"</a> , betrieben von Facebook Inc, 1601 S. California Ave, Palo Alto, CA 94304, USA ("Facebook"). Sie können die Facebook-Plug-Ins am Facebook-Logo und dem "Gefällt mir"-Button erkennen.\
                        Wenn Sie eine Webseite aufrufen, die ein solches Plug-In enthält, stellt Ihr Browser eine direkte Verbindung zum Facebook Server her. Facebook übermittelt sodann den Inhalt des Plug-Ins an Ihren Browser, der das Plug-In wiederum in die Webseite einbindet. Facebook erhält auf diese Weise die Information, dass ein Nutzer mit einer bestimmten IP-Adresse, nämlich der IP-Adresse, die Ihrem Internetzugang zur Zeit der Übertragung zugewiesen ist, die entsprechende Webseite besucht hat. Sofern Sie zur gleichen Zeit in ihren Facebook-Account eingeloggt sind, kann Facebook Ihren Besuch bei uns Ihrem Facebook-Account zuordnen, auch wenn Sie den "Gefällt mir"-Button nicht betätigen. Wenn Sie das Plug-In durch Anklicken des "Gefällt mir"-Button nutzen, wird dies von Ihrem Browser ebenfalls an Facebook übermittelt und dort gespeichert. Sollten Sie nicht wünschen, dass Facebook über unsere Webseite Daten sammelt und Ihrem Facebook-Account zuordnet, loggen Sie sich bitte bei Facebook aus, bevor Sie unsere Webseite besuchen.\
                        Weitere Informationen über den Zweck und den Umfang der Erhebung, Speicherung und Nutzung dieser Daten durch Facebook sowie hinsichtlich Ihrer diesbezüglichen Rechte und der möglichen Einstellungen Ihres Facebook-Accounts, um Ihre privaten Daten zu schützen, finden Sie in der Facebook-Datenschutzerklärung.</p>\
                        <h4>b) Google+ Plugin</h4>\
                        <p>Wir nutzen gegebenenfalls Plug-Ins des sozialen Netzwerks von Google (Google+), welche von der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“) in den USA betrieben wird. Sie können die Plug-Ins an der farbigen Schrift „Google+“ auf unserer Seite erkennen. Wenn Sie unsere Webseite aufrufen, die ein solches Plug-In enthält, stellt Ihr Browser eine direkte Verbindung zum Google Server her. Der Inhalt des Plug-In wird von Google direkt an Ihren Browser übermittelt und von diesem in die Webseite eingebunden. Wir haben keinen Einfluss auf den Umfang der erhobenen Daten die Google erhebt. Google erhält die Information, dass ein Nutzer mit seiner IP-Adresse unsere Webseite besucht hat. Wenn sie selbst bei Google+ eingeloggt sind und nicht möchten, dass Google Daten über Ihren Besuch speichert, müssen Sie sich vor Besuch unserer Webseite in Ihrem Google Account ausloggen. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Google, sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte Googles Datenschutzhinweisen zu Google+.</p>\
                        <h4>c) Twitter-Plugin</h4>\
                        <p>Unsere Seite verwendet zum Teil das Plug-In des Netzwerks Twitter (<a href="https://twitter.com/about/resources/buttons">https://twitter.com/about/resources/buttons</a>). Das Plug-In wird durch die Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA 94107, USA angeboten. Das Plug-In ist durch die hellblaue Schrift „twitter“ auf weißem Grund auf unserer Webseite zu erkennen. Durch das Twitter Plug-In ist es möglich, Beiträge von uns auf Twitter zu teilen oder uns bei Twitter zu folgen. Wenn Sie unsere Webseite aufrufen, die ein solches Plug-In enthält, stellt Ihr Browser eine direkte Verbindung zum Twitter Server her. Der Inhalt des Plug-In wird von Twitter direkt an Ihren Browser übermittelt. Wir haben keinen Einfluss auf den Umfang der Daten, die Twitter erhebt. Nach unserem Kenntnisstand werden die IP-Adresse des Nutzers, sowie die URL der jeweiligen Webseite beim Klicken auf das Plug-In an Twitter übermittelt, dabei jedoch nur zum Zwecke der Darstellung des Plug-Ins genutzt. Weitere Informationen finden Sie in der Datenschutzerklärung von Twitter</p>\
                        <h4>d) Xing-Plugin</h4>\
                        <p>Unsere Seite verwendet teilweise auch den Share-Button des Netzwerks XING (<a href="https://www.xing.com/app/share?op=button_builder">https://www.xing.com/app/share?op=button_builder</a>) Das Plug-In wird durch die XING AG, Gänsemarkt 43, 20354 Hamburg („XING“) angeboten. Beim Aufruf unserer Webseite wird über Ihren Browser kurzfristig eine Verbindung zu den Servern von XING aufgebaut. XING speichert keine personenbezogenen Informationen über Nutzer, die unserer Webseite aufgerufen haben. Die IP-Adresse wird ebenfalls nicht von XING gespeichert. Es findet auch keine Auswertung Ihres Nutzungsverhaltens über die Verwendung von Cookies im Zusammenhang mit dem XING Share-Button statt. Eine zusammenfassende Darstellung der Funktionalitäten des Share-Buttons erhalten Sie unter: https://www.xing.com/app/share?op=data_protection. Eine allgemeine Beschreibung der Datenverarbeitung erhalten Sie in der XING Datenschutzerklärung.</p>\
                        <h4>e) Google Analytics</h4>\
                        <p>Diese Webseite benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Goo­gle“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Webseite wird Google diese Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und um weitere mit der Webseitennutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Webseite vollumfänglich werden nutzen können.\
                        Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link (<a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>) verfügbare Browser-Plug-In herunterladen und installieren.\
                        Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter <a href="https://www.google.com/analytics/terms/de.html">https://www.google.com/analytics/terms/de.html</a> bzw. unter <a href="https://www.google.de/intl/de/policies/">https://www.google.de/intl/de/policies/</a>. Wir weisen Sie darauf hin, dass auf dieser Webseite Google Analytics um den Code „anonymizeIp“ erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu gewährleisten.</p>\
                        <h4>f) Google AdWords Conversion-Tracking</h4>\
                        <p>Wir nutzen das Online-Werbeprogramm „Google AdWords“ und im Rahmen von Google AdWords das Conversion-Tracking. Das Google Conversion Tracking ist ein Analysedienst der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“). Wenn Sie auf eine von Google geschaltete Anzeige klicken, wird ein Cookie für das Conversion-Tracking auf Ihrem Rechner abgelegt. Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit, enthalten keine personenbezogenen Daten und dienen somit nicht der persönlichen Identifizierung.\
                        Wenn Sie bestimmte Internetseiten unserer Webseite besuchen und das Cookie noch nicht abgelaufen ist, können Google und wir erkennen, dass Sie auf die Anzeige geklickt haben und zu dieser Seite weitergeleitet wurden. Jeder Google AdWords-Kunde erhält ein anderes Cookie. Somit besteht keine Möglichkeit, dass Cookies über die Webseiten von AdWords-Kunden nachverfolgt werden können.\
                        Die Informationen, die mithilfe des Conversion-Cookie eingeholt werden, dienen dazu, Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für Conversion-Tracking entschieden haben. Hierbei erfahren die Kunden die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen.\
                        Wenn Sie nicht am Tracking teilnehmen möchten, können Sie dieser Nutzung widersprechen, indem Sie die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern (Deaktivierungsmöglichkeit). Sie werden sodann nicht in die Conversion-Tracking Statistiken aufgenommen. Weiterführende Informationen sowie die Datenschutzerklärung von Google finden Sie unter: <a href="https://www.google.com/policies/technologies/ads/">https://www.google.com/policies/technologies/ads/</a>, <a href="https://www.google.de/policies/privacy/">https://www.google.de/policies/privacy/</a></p>\
                        <h4>g) Verwendung von Hotjar</h4>\
                        <p>Für die Verbesserung der Nutzererfahrung auf unseren Internetseiten setzen wir die Software Hotjar (<a href="https://www.hotjar.com">https://www.hotjar.com</a>, 3 Lyons Range, 20 Bisazza Street, Sliema SLM 1640, Malta, Europe) ein. Mittels Hotjar können wir das Nutzerverhalten (Mausbewegungen, Klicks, Scrollhöhe etc.) auf unseren Internetseiten messen und auswerten. Zu diesem Zweck setzt Hotjar Cookies auf Endgeräten der Nutzer und kann Daten von Nutzern wie z. B. Browser Informationen, Betriebssystem, Verweildauer auf der Seite etc. speichern. Mehr über die Datenverarbeitung durch Hotjar finden Sie unter www.hotjar.com/privacy.</p>\
                        <h3>Betroffenenrechte</h3>\
                        <p>Sie haben das Recht:</p>\
                        <ul class="modal-list">\
                        <li>gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;</li>\
                        <li>gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;</li>\
                        <li>gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;</li>\
                        <li>gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;</li>\
                        <li>gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;</li>\
                        <li>gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die allein auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen;</li>\
                        <li>gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Kanzleisitzes wenden.</li>\
                        </ul>\
                        <h3>Widerspruchsrecht</h3>\
                        <p>Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird.\
                        Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an uns.</p>\
                        <h3>Datensicherheit</h3>\
                        <p>Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers.\
                        Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.</p>\
                        <h3>Aktualität und Änderung dieser Datenschutzerklärung</h3>\
                        <p>Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website unter <a href="https://www.bigdreams.eu/datenschutz">https://www.bigdreams.eu/datenschutz</a> von Ihnen abgerufen und ausgedruckt werden.</p>\
                                </div>\
                                                                                        <div class="modal-footer">\
                                                                                            <button type="button" class="btn btn-default" data-dismiss="modal">Schließen</button>\
                                                                                        </div>\
                                                                                    </div>\
                                                                                </div>\
                                                                                </div>');
    /* /Modalbox Datenschutz */

    /* Modalbox Rahmenvertrag*/
    $('body').append('<div class="modal fade" id="infoRahmenvertrag" tabindex="-1" role="dialog">\
        <div class="modal-dialog">\
            <div class="modal-content">\
                <div class="modal-header">\
                    <button type="button" class="close" data-dismiss="modal">&times;</button>\
                    <h4 class="modal-title">Rahmenvertrag</h4>\
                </div>\
                <div class="modal-body">\
                    <p>...</p>\
                </div>\
                <div class="modal-footer">\
                    <button type="button" class="btn btn-default" data-dismiss="modal">Schließen</button>\
                </div>\
            </div>\
        </div>\
        </div>');
    /* /Modalbox Rahmenvertrag */

    /* Modalbox AGB*/
    $('body').append(`<div class="modal fade" id="infoAGB" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title">AGB</h4>
                </div>
                <div class="modal-body">
        <h2 class="small-space">Allgemeine Geschäftsbedingungen (AGB) BigDreams.eu</h2>
    <h3 class="space master">§ 1 Geltungsbereich, Begriffsbestimmungen</h3>
    <div class="small-space">(1) Für die Geschäftsbeziehung zwischen BigDreams.eu bzw. Robin Vij (nachfolgend „Anbieter“) und dem Kunden (nachfolgend „Kunde“) gelten ausschließlich die nachfolgenden Allgemeinen Geschäftsbedingungen in ihrer zum Zeitpunkt der Bestellung gültigen Fassung. Abweichende Allgemeine Geschäftsbedingungen des Bestellers werden nicht anerkannt, es sei denn, der Anbieter stimmt ihrer Geltung ausdrücklich schriftlich zu.</div>
    <div class="small-space">(2) Der Kunde ist Verbraucher, soweit der Zweck der georderten Lieferungen und Leistungen nicht überwiegend seiner gewerblichen oder selbständigen beruflichen Tätigkeit zugerechnet werden kann. Dagegen ist Unternehmer jede natürliche oder juristische Person oder rechtsfähige Personengesellschaft, die beim Abschluss des Vertrags in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt. „Kunden“ im Sinne dieser AGB sind sowohl Verbraucher als auch Unternehmer.</div>
    <div class="small-space">(3) Die Buchung des Kunden wird nach den zum Zeitpunkt der Bestellung jeweils gültigen AGB ausgeführt. Die AGB in ihrer aktuellen Fassung stehen dem Kunden auf der Internetseite des Anbieters unter https://www.bigdreams.eu/agb zur Verfügung.</div>
    <h3 class="small-space master">§ 2 Vertragsschluss online</h3>
    <div class="small-space">(1) Der Kunde kann aus dem Sortiment des Anbieters Produkte und Leistungen, insbesondere Schulungsseminare und sportive Kursangebote aller Art, auswählen und diese über den Button „jetzt buchen“ bzw. „Produkt Kaufen“ markieren und sofern technisch möglich, in einem so genannten Warenkorb sammeln. Über den Button „Kurs kostenlos buchen“ oder „Kurs kostenpflichtig buchen“ bzw. „Jetzt kostenpflichtig bestellen“ unter „Daten erfassen“ gibt er einen verbindlichen Antrag zum Vertragsabschluss über die markierten / im Warenkorb befindlichen Leistungen ab. Vor Abschicken der Bestellung kann der Kunde die Daten jederzeit ändern und einsehen. Der Antrag kann jedoch nur abgegeben und übermittelt werden, wenn der Kunde durch Anklicken unter „AGB“ diese Vertragsbedingungen akzeptiert und dadurch in seinen Antrag aufgenommen hat.</div>
    <div class="small-space">(2) Der Anbieter schickt daraufhin dem Kunden eine automatische Empfangsbestätigung per E-Mail zu, in welcher die Bestellung des Kunden nochmals aufgeführt wird und die der Kunde über die Funktion „Drucken“ ausdrucken kann. Die automatische Empfangsbestätigung dokumentiert lediglich, dass die Bestellung des Kunden beim Anbieter eingegangen ist und stellt keine Annahme des Antrags dar. Die Darstellung der Produkte und Leistungen im Online-Angebot stellt kein rechtlich bindendes Angebot des Anbieters an den Kunden dar. Der Vertrag kommt erst nach Prüfung der vorhandenen Kapazitäten durch die Abgabe der Annahmeerklärung durch den Anbieter zustande, die mit einer gesonderten E-Mail (Auftragsbestätigung) versandt wird. In dieser E-Mail oder in einer separaten E-Mail wird der Vertragstext (bestehend aus Bestellung, AGB und Auftragsbestätigung) dem Kunden von uns auf einem dauerhaften Datenträger (E-Mail oder Papierausdruck) zugesandt. Der Vertragstext wird unter Wahrung des Datenschutzes gespeichert. Der Kunde hat zudem die Möglichkeit, die AGB auf der Homepage des Anbieters abzurufen und in wiedergabefähiger Form zu speichern.</div>
    <div class="small-space">(3) Übersteigt die Anzahl der Anmeldungen die vorhandenen Kapazitäten, so werden diese Anmeldungen nach ihrem zeitlichen Eingang auf eine Warteliste gesetzt und rücken beim Freiwerden von Plätzen nach. Kann eine Anmeldung nicht berücksichtigt werden, wird eine entsprechende Benachrichtigung zur Information umgehend an den Kunden versandt.</div>
    <div class="small-space">(4) Der Vertragsschluss erfolgt in deutscher Sprache.</div>
    <h3 class="small-space master">§ 3 Vertragsschluss offline</h3>
    <div class="small-space">(1) Die Darstellung der Leistungen des Anbieters in den Unternehmens-Faltblättern stellt kein rechtlich bindendes Angebot an den Kunden dar. Erst der Kunde gibt mit Abgabe des Anmeldeformulars beim Anbieter ein rechtlich verbindliches Vertragsangebot in Bezug auf die ausgewählte Veranstaltung(en) ab.</div>
    <div class="small-space">(2) Ein Vertrag über den oder die gewünschte Veranstaltung(en) kommt erst dann zustande, wenn die Bestellung nach Prüfung der vorhandenen Kapazitäten durch den Anbieter ausdrücklich gegenüber dem Kunden bestätigt wird (Auftragsbestätigung). Die Auftragsbestätigung wird durch den Anbieter mit einer gesonderten E-Mail versandt. In dieser E-Mail oder in einer separaten E-Mail wird der Vertragstext (bestehend aus Bestellung, AGB und Auftragsbestätigung) dem Kunden von uns auf einem dauerhaften Datenträger (E-Mail oder Papierausdruck) zugesandt. Der Vertragstext wird unter Wahrung des Datenschutzes gespeichert. Der Kunde hat zudem die Möglichkeit, die AGB auf der Homepage des Anbieters abzurufen und in wiedergabefähiger Form zu speichern.</div>
    <div class="small-space">(3) Übersteigt die Anzahl der Anmeldungen die vorhandenen Kapazitäten, so werden diese Anmeldungen nach ihrem zeitlichen Eingang auf eine Warteliste gesetzt und rücken beim Freiwerden von Plätzen nach. Kann eine Anmeldung nicht berücksichtigt werden, wird eine entsprechende Benachrichtigung zur Information umgehend an den Kunden versandt.</div>
    <div class="small-space">(4) Der Vertragsschluss erfolgt in deutscher Sprache.</div>
    <h3 class="small-space master">§ 4 Leistungsumfang</h3>
    <div class="small-space">Der jeweilige Leistungsumfang ist in der Veranstaltungsbeschreibung spezifiziert. Der Anbieter behält sich vor, fachliche Inhalte weiterzuentwickeln und die Veranstaltungsdauer geringfügig anzupassen, soweit dies dem Kunden zumutbar ist. In diesem Fall ist der Anbieter bemüht, notwendige Änderungen des Programms rechtzeitig mitzuteilen.</div>
    <h3 class="small-space master">§ 5 Preise und Zahlungen</h3>
    <div class="small-space">(1) Die Teilnahmegebühr ist vom Kunden unabhängig von Leistungen Dritter zu zahlen. Anfallende Gebühren für Prüfungen und Leistungen Dritter werden gesondert berechnet. Die jeweilige Gebühr ist nach Erhalt der entsprechenden Rechnung zu den in der Rechnung genannten Bedingungen und Terminen zu entrichten.</div>
    <div class="small-space">(2) Ist die Fälligkeit der Zahlung nach dem Kalender bestimmt, so kommt der Kunde bereits durch Versäumung des Termins in Verzug. Ist die Gebühr in Teilbeträgen zu entrichten, so wird im Falle eines Zahlungsverzugs der Restbetrag in einer Summe fällig.</div>
    <div class="small-space">(3) Die Verpflichtung des Kunden zur Zahlung von Verzugszinsen schließt die Geltendmachung weiterer Verzugsschäden durch den Anbieter nicht aus.</div>
    <div class="small-space">(4) Alle Preise, die auf der Website des Anbieters angegeben sind, verstehen sich einschließlich der jeweils gültigen gesetzlichen Umsatzsteuer.</div>
    <div class="small-space">(5) Mit der Gebühr sind alle Leistungen des Anbieters nach § 4 abgegolten.</div>
    <div class="small-space">(6) Kosten, die den Teilnehmern und/oder dem Kunden im Zusammenhang mit der Leistungserbringung durch den Anbieter entstehen (insbesondere Fahrtkosten, sonstige Verpflegungs- und Übernachtungskosten), sind von diesen/diesem selbst zu tragen.</div>
    <h3 class="small-space master">§ 6 Stornierung</h3>
    <div class="small-space">(1) Ein Wechsel des Dozenten respektive des Trainers oder eine Änderung des Veranstaltungsortes berechtigen den Kunden nicht zum Rücktritt vom Vertrag.</div>
    <div class="small-space">(2) Im Übrigen kann der Kunde bis spätestens 4 (vier) Wochen vor Beginn der Maßnahme vom Vertrag kostenfrei zurücktreten. Bei Rücktritt vom Vertrag bis 14 Tage vor Veranstaltungsbeginn wird statt der Teilnahmegebühr eine Verwaltungskostenpauschale in Höhe der Hälfte der Teilnahmegebühr berechnet. Bei späteren Stornierungen oder Nichterscheinen ist die gesamte Teilnahmegebühr zu zahlen.</div>
    <div class="small-space">(3) Die Stornierung ist schriftlich oder per E-Mail an hallo.naleepa@gmail.com zu erklären. Kunden, die sich nicht frist- und formgerecht abmelden, oder während der Veranstaltung zurücktreten, sind zur Zahlung der vollen Lehrgangsgebühr verpflichtet. Dem Kunden bleibt bezüglich der Verwaltungskostenpauschale bzw. Teilnahmegebühr der Nachweis unbenommen, ein Schaden sei nicht entstanden oder wesentlich niedriger, beispielsweise bei Stellung eines Ersatzteilnehmers.</div>
    <div class="small-space">(4) Der Anbieter behält sich vor, die Leistung, insbesondere Trainings- und Schulungsmaßnahme oder einzelne Trainings- und Schulungseinheit(en), kurzfristig bis 12 Uhr mittags des Vortages der Veranstaltung abzusagen, wenn diese nicht wie vereinbart durchgeführt werden kann (können), insbesondere wegen zu geringer Teilnehmerzahl oder wenn</div>
    <div class="small-space">- ein oder mehrere Dozenten/Trainer und/oder<br />- Schulungsmaterialien</div>
    <div class="small-space">ausfallen.</div>
    <div class="small-space">Der Anbieter wird den Kunden unverzüglich informieren. Bereits gezahlte Gebühren werden, sofern die ausgefallenen Einheiten vom Anbieter nicht innerhalb von 6 (sechs) Kalendermonaten nachgeholt werden, zurückerstattet. Der Schulungsanbieter übernimmt keine Kosten des Kunden, die durch Stornierungen entstehen (z.B. Stornogebühren für Fahrt und Übernachtung), soweit der Trainings- und Schulungsanbieter die Stornierung nicht zu vertreten hat.</div>
    <h3 class="small-space master">§ 7 Höhere Gewalt</h3>
    <div class="small-space">Schwerwiegende Ereignisse, wie insbesondere höhere Gewalt (insbesondere Naturkatastrophen), Ansteckungskrankheiten allgemein und insbesondere epdemischer oder pandemischer Natur wie z.B. Corona, Arbeitskämpfe, Unruhen, kriegerische oder terroristische Auseinandersetzungen, die unvorhersehbare Folgen für die Leistungsdurchführung nach sich ziehen, befreien die Vertragsparteien für die Dauer der Störung und im Umfang ihrer Wirkung von ihren Leistungspflichten, selbst wenn sie sich in Verzug befinden sollten. Eine automatische Vertragsauflösung ist damit nicht verbunden. Die Vertragsparteien sind verpflichtet, sich von einem solchen Hindernis zu benachrichtigen und ihre Verpflichtungen den veränderten Verhältnissen nach Treu und Glauben anzupassen.</div>
    <h3 class="small-space master">§ 8 Haftung</h3>
    <div class="small-space">(1) Ansprüche des Kunden auf Schadensersatz sind ausgeschlossen. Hiervon ausgenommen sind Schadensersatzansprüche des Kunden aus der Verletzung des Lebens, des Körpers, der Gesundheit oder aus der Verletzung wesentlicher Vertragspflichten (Kardinalpflichten) sowie die Haftung für sonstige Schäden, die auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung des Anbieters, seiner gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen. Wesentliche Vertragspflichten sind solche, deren Erfüllung zur Erreichung des Ziels des Vertrags notwendig ist.</div>
    <div class="small-space">(2) Bei der Verletzung wesentlicher Vertragspflichten haftet der Anbieter nur auf den vertragstypischen, vorhersehbaren Schaden, wenn dieser einfach fahrlässig verursacht wurde, es sei denn, es handelt sich um Schadensersatzansprüche des Kunden aus einer Verletzung des Lebens, des Körpers oder der Gesundheit.</div>
    <div class="small-space">(3) Die Einschränkungen der Abs. 1 und 2 gelten auch zugunsten der gesetzlichen Vertreter und Erfüllungsgehilfen des Anbieters, wenn Ansprüche direkt gegen diese geltend gemacht werden.</div>
    <div class="small-space">(4) Der Einwand des Mitverschuldens bleibt den Parteien unbenommen.</div>
    <h3 class="small-space master">§ 9 Urheberrecht</h3>
    <div class="small-space">Veranstaltungsunterlagen werden üblicherweise zu Beginn der und begleitend zur Veranstaltung ausgehändigt, können aber erforderlichenfalls, z.B. bei Änderungen des Veranstaltungsinhalts, auch nachgereicht werden. Die Veranstaltungsunterlagen sind urheberrechtlich geschützt. Der Anbieter hält alle Rechte an den Veranstaltungsunterlagen, insbesondere die der Übersetzung und Vervielfältigung, und am Veranstaltungskonzept. Ohne schriftliche Genehmigung des Anbieters darf kein Teil dieser Unterlagen in irgendeiner Form verarbeitet, vervielfältigt oder weitergegeben werden. Der Kunde haftet für sämtliche Schäden, die auf von ihm veranlasste, nicht autorisierte Weitergabe von Unterlagen oder Datenträgern zumindest mitursächlich zurückgeführt werden können.</div>
    <div class="space" style="border:2px solid #DF0101;padding:10px;">
    <h3 class="small-space master">§ 10 <u>WIDERRUFSBELEHRUNG</u></h3>
    <div class="small-space">(1) Verbraucher haben bei Abschluss eines Fernabsatzgeschäfts und bei außerhalb von Geschäftsräumen geschlossenen Verträgen grundsätzlich ein gesetzliches Widerrufsrecht, über das der Anbieter nach Maßgabe des gesetzlichen Musters nachfolgend informiert. In Absatz (2) findet sich ein Muster-Widerrufsformular.</div>
    <div class="small-space text-center font-m"><u>Widerrufsbelehrung</u></div>
    <div class="small-space"><span class="rot-braun-strong">Widerrufsrecht</span><br />Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.</div>
    <div class="small-space">Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses (bei Verträgen über Dienstleistungen wie Seminare und Kurse) bzw. vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat (bei Warenkauf).</div>
    <div class="small-space">Um Ihr Widerrufsrecht auszuüben, müssen Sie uns</div>
    <div class="space"><strong>Robin Vij<br />Ammerweg 19<br />56564 Neuwied<br />Telefon: 0179-10061561<br />hallo.naleepa@gmail.com</strong></div>
    <div class="space">mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.</div>
    <div class="small-space">Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</div>
    <div class="space"><span class="rot-braun-strong">Folgen des Widerrufs</span><br />Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</div>
    <div class="small-space">Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen sollen, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.</div>
    <div class="small-space">(2) Über das Muster-Widerrufsformular informiert der Anbieter nach der gesetzlichen Regelung wie folgt:</div>
    <div class="space"><span class="rot-braun-strong">Muster-Widerrufsformular</span></div>
    <div class="small-space">(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)</div>
    <div class="small-space">– An</div>
    <div class="small-space">Robin Vij<br />Ammerweg 19<br />56564 Neuwied<br />hallo.naleepa@gmail.com</div>
    <div class="small-space">– Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)</div>
    <div class="small-space">– Bestellt am (*)/erhalten am (*)</div>
    <div class="small-space">– Name des/der Verbraucher(s)<br />– Anschrift des/der Verbraucher(s)</div>
    <div class="small-space">– Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</div>
    <div class="small-space">– Datum</div>
    <div class="small-space">____________________<br />(*) Unzutreffendes streichen.</div>
    </div>
    <h3 class="space master">§ 11 Schlussbestimmungen</h3>
    <div class="small-space">(1) Auf Verträge zwischen dem Anbieter und den Kunden findet das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts und unter Ausschluss der Verweisungsnormen des Kollisionsrechts Anwendung. Die gesetzlichen Vorschriften zur Beschränkung der Rechtswahl und zur Anwendbarkeit zwingender Vorschriften insbes. des Staates, in dem der Kunde als Verbraucher seinen gewöhnlichen Aufenthalt hat, bleiben unberührt.</div>
    <div class="small-space">(2) Sofern es sich beim Kunden um einen Kaufmann, eine juristische Person des öffentlichen Rechts oder um ein öffentlich-rechtliches Sondervermögen handelt, ist Gerichtsstand für alle Streitigkeiten aus Vertragsverhältnissen zwischen dem Kunden und dem Anbieter der Sitz des Anbieters.</div>
    <div class="small-space">(3) <u>Streitbeilegung in Verbrauchersachen</u>: Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von Streitigkeiten geschaffen. Die Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten betreffend vertragliche Verpflichtungen, die aus Online-Kaufverträgen oder Online-Dienstleistungsverträgen erwachsen. Nähere Informationen sind unter dem folgenden Link verfügbar: <a href="http://ec.europa.eu/consumers/odr" target="_blank">http://ec.europa.eu/consumers/odr</a> . Den Anbieter erreichen Sie u.a. per E-Mail unter hallo.naleepa@gmail.com. Der Anbieter ist weder verpflichtet noch bereit, an dem Streitschlichtungsverfahren teilzunehmen.</div>
    <div class="small-space">(4) Der Vertrag bleibt auch bei rechtlicher Unwirksamkeit einzelner Punkte in seinen übrigen Teilen verbindlich. Anstelle der unwirksamen Punkte treten, soweit vorhanden, die gesetzlichen Vorschriften. Soweit dies für eine Vertragspartei eine unzumutbare Härte darstellen würde, wird der Vertrag jedoch im Ganzen unwirksam.</div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Schließen</button>
                </div>
            </div>
        </div>
        </div>`);
    /* /Modalbox AGB */

    /* Modalbox Zunge */
    $('body').append('<div class="modal fade" id="infoTongue" tabindex="-1" role="dialog">\
        <div class="modal-dialog">\
            <div class="modal-content">\
                <div class="modal-header">\
                    <button type="button" class="close" data-dismiss="modal">&times;</button>\
                    <h4 class="modal-title">Produkte</h4>\
                </div>\
                <div class="modal-body">\
                    <p>...</p>\
                </div>\
                <div class="modal-footer">\
                    <button type="button" class="btn btn-default" data-dismiss="modal">Schließen</button>\
                </div>\
            </div>\
        </div>\
        </div>');
    /* /Modalbox AGB */
});