$(function() {
    'use strict';

// Buchungsformular
    //$('#courseType').val(1); // Zurücksetzen des Kurstyps
    $('#amountOfPersons').val(1); // Zurücksetzen der Teilnehmerzahl

    $('input[name=isCompanyOrPrivate]').on('change', function() {
        if ('private' === $(this).val()) {
            $('#privateFieldContainer').removeClass('hidden').addClass('show');
            $('.companyFieldContainer').removeClass('show').addClass('hidden');
        } else {
            $('#privateFieldContainer').removeClass('show').addClass('hidden');
            $('.companyFieldContainer').removeClass('hidden').addClass('show');
        }
    });

    $('#amountOfPersons').on('change', function() {
        // Wenn Gutscheincode vorhanden, dann prüfen und Werte nach Anzahl der Personen ändern!
        if ($('#bookingCode').val()) {
            checkCodeAndChangeValues();
        } else {
            var total = $('#priceNew').val() * $(this).val();
            $('.priceTotal').html(formatThousands(total, 2));
        }

        $('#btnAmountOfPerson').html($(this).val());
        var personOrPersons = 1 < $(this).val() ? 'Personen' : 'Person';
        $('#btnPersons').html(personOrPersons);

        // Falsche Feldeingaben löschen!
        for (var i = 1; i < 10; i++) {
            if ((i+1) > $(this).val()) {
                $('.person-'+(i+1)).addClass('hidden');
                $('.cleardesign-wrapper .person-'+(i+1)+' input[type="text"]').val('');
            } else {
                $('.person-'+(i+1)).removeClass('hidden');
            }
        }

        $('.personContainer').hide();
        $('.personContainer').delay(200).slideDown('slow', 'swing', function(){});
    });

    /**
     * Format to German Decimal
     * @param {number} number
     * @param {number} fraction
     * @returns {string}
     */
    function formatThousands(number, fraction) {
        let result = (fraction ? number.toFixed(fraction) : '' + number).split('.');

        return result[0].replace(/\B(?=(\d{3})+(?!\d))/g, ('.')) + (result[1] ? ',' + result[1] : '');
    }

    /**
     * Formats given number according to given options.
     * @param {number} number The number to be formatted.
     * @param {!Object.<string, *>} options The formatting options.
     * @return {string} The formatted number string.
     */
    function formatNumber(number, options) {
        let result = (options['fraction'] ? number.toFixed(options['fraction']) : '' + number).split('.');
        return result[0].replace(/\B(?=(\d{3})+(?!\d))/g, (options['grouping'])) +
            (result[1] ? options['decimal'] + result[1] : '');
    }

    // Kontakt Basis Formular
    function showRegisterForm() {
        $('.grafic-header').addClass('unsharp');
        $('#registerFormBtn, #boniFormBtn').parent().remove();
        $('.contactBasisForm div.dp-none, .adresseBoniForm div.dp-none, .contactBasisTextForm div.dp-none').slideDown();
    }

    $('.cleardesign-wrapper .movetop').click(function(e){
        e.preventDefault();
        $('html, body').animate({scrollTop: 0}, 600);
        showRegisterForm();
    });

    $('#registerFormBtn, #boniFormBtn').click(function(e) {
        e.preventDefault();
        showRegisterForm();
    });

    // Schnellcheck Schaltfläche
    $('.show-quickcheck').click(function(e) {
        e.preventDefault();

        // Klickt der User jetzt auf den orangenen Button und Häkchen ist noch gesetzt passiert nichts.
        if (true === $('#allowQuickcheck').prop('checked')) {
            return false;
        }

        $('.quickcheck').toggle(500, 'linear', function () {
            // Alle Feldinhalte löschen wenn Quickcheck geschlossen wird.
            $('.cleardesign-wrapper .quickcheck input[type="text"]').val();
            $('.cleardesign-wrapper .quickcheck select').val(0);
        });
    });

    // Wenn Schnellcheck geklickt wird
    $('#allowQuickcheck').click(function(){
        if (true === $(this).prop('checked')) {
            $('.show-quickcheck').addClass('grey');
            $('.cleardesign-wrapper .quickcheck input[type="text"]').prop('readonly', false)
                .removeClass('deactive-field');
            $('.cleardesign-wrapper .quickcheck select').prop('disabled', false)
                .removeClass('deactive-field');
        } else {
            $('.show-quickcheck').removeClass('grey');
            $('.cleardesign-wrapper .quickcheck input[type="text"]').prop('readonly', true)
                .addClass('deactive-field')
                .val('');
            $('.cleardesign-wrapper .quickcheck select').prop('disabled', true)
                .addClass('deactive-field')
                .val(0);
        }
    });

    $('.contactForm, .cvForm, .quickcheckForm, .contactBasisForm, .adresseBoniForm, .contactBasisTextForm, .bookingForm').submit(function(e) {
        e.preventDefault();

        var outSite   = '' === $('#outSite').val() ? location.href : $('#outSite').val();
        var formName  = $(this).attr('name');
        var state     = true;
        var fullname  = $('input#fullname').val();
        var email     = $('input#email').val();
        var phone     = $('input#phone').val();
        var message   = $('textarea#message').val();
        var firstName = 'undefined' !== fullname ? fullname : false; // For Success/Failure Message
        // Check for white space in name for Success/Fail message
        if (true == firstName) {
            if (firstName.indexOf(' ') >= 0) {
                firstName = fullname.split(' ').slice(0, -1).join(' ');
            }
        }

        if ('bookingForm' === formName) {
            var customerFirstname1  = $('input#customerFirstname1').val();
            var customerLastname1   = $('input#customerLastname1').val();
            var customerPhone1      = $('input#customerPhone1').val();
            var customerEmail1      = $('input#customerEmail1').val();
            var isCompanyOrPrivate  = $('input[name="isCompanyOrPrivate"]:checked').val();
            var companyname         = $('input#companyname').val();
            var costFirstname       = $('input#costFirstname').val();
            var costLastname        = $('input#costLastname').val();
            var street              = $('input#street').val();
            var housenumber         = $('input#housenumber').val();
            var zipcode             = $('input#zipcode').val();
            var city                = $('input#city').val();

            if ('' == customerFirstname1) {
                $('input#customerFirstname1 ~ p.text-danger').html($('input#customerFirstname1').data('validation-message'));
                state = false;
            } else {
                $('input#customerFirstname1 ~ p.text-danger').html('');
            }

            if ('' == customerLastname1) {
                $('input#customerLastname1 ~ p.text-danger').html($('input#customerLastname1').data('validation-message'));
                state = false;
            } else {
                $('input#customerLastname1 ~ p.text-danger').html('');
            }

            if ('' == customerPhone1) {
                $('input#customerPhone1 ~ p.text-danger').html($('input#customerPhone1').data('validation-message'));
                state = false;
            } else {
                $('input#customerPhone1 ~ p.text-danger').html('');
            }

            if (false == isEmail(customerEmail1)) {
                $('input#customerEmail1 ~ p.text-danger').html($('input#customerEmail1').data('validation-message'));
                state = false;
            } else {
                $('input#customerEmail1 ~ p.text-danger').html('');
            }

            if ('private' === isCompanyOrPrivate && $('#agb').length) {
                if ('' == costFirstname) {
                    $('input#costFirstname ~ p.text-danger').html($('input#costFirstname').data('validation-message'));
                    state = false;
                } else {
                    $('input#costFirstname ~ p.text-danger').html('');
                }

                if ('' == costLastname && $('#agb').length) {
                    $('input#costLastname ~ p.text-danger').html($('input#costLastname').data('validation-message'));
                    state = false;
                } else {
                    $('input#costLastname ~ p.text-danger').html('');
                }
            }

            if ('company' === isCompanyOrPrivate && $('#agb').length) {
                if ('' == companyname && $('#agb').length) {
                    $('input#companyname ~ p.text-danger').html($('input#companyname').data('validation-message'));
                    state = false;
                } else {
                    $('input#companyname ~ p.text-danger').html('');
                }
            }

            if ('' == street && $('#agb').length) {
                $('input#street ~ p.text-danger').html($('input#street').data('validation-message'));
                state = false;
            } else {
                $('input#street ~ p.text-danger').html('');
            }

            if ('' == housenumber && $('#agb').length) {
                $('input#housenumber ~ p.text-danger').html($('input#housenumber').data('validation-message'));
                state = false;
            } else {
                $('input#housenumber ~ p.text-danger').html('');
            }

            if ('' == zipcode && $('#agb').length) {
                $('input#zipcode ~ p.text-danger').html($('input#zipcode').data('validation-message'));
                state = false;
            } else {
                $('input#zipcode ~ p.text-danger').html('');
            }

            if ('' == city && $('#agb').length) {
                $('input#city ~ p.text-danger').html($('input#city').data('validation-message'));
                state = false;
            } else {
                $('input#city ~ p.text-danger').html('');
            }
        }

        if ('adresseBoniForm' === formName) {
            var companyname = $('input#companyname').val();
            var dateOfBirth = $('input#dateOfBirth').val();
            var firstname   = $('input#firstname').val();
            var lastname    = $('input#lastname').val();
            var street      = $('input#street').val();
            var zipcode     = $('input#zipcode').val();
            var city        = $('input#city').val();
        }

        if ('quickcheckForm' === formName) {
            var checkAgreement   = $('input#allowQuickcheck').prop('checked');
            var checkCompanyname = $('input#quickCompanyname').val();
            var checkSalutation  = $('select#salutation').val();
            var checkFirstname   = $('input#firstname').val();
            var checkLastname    = $('input#lastname').val();
            var checkStreet      = $('input#street').val();
            var checkZipcode     = $('input#zipcode').val();
            var checkCity        = $('input#city').val();

            // Wenn, Schnellcheck erwünscht dann alle Schnellcheckfelder erforderlich.
            if (true === checkAgreement && '' == checkCompanyname) {
                $('input#quickCompanyname ~ p.text-danger').html($('input#quickCompanyname').data('validation-message'));
                state = false;
            } else {
                $('input#quickCompanyname ~ p.text-danger').html('');
            }

            if (true === checkAgreement && '' == checkSalutation) {
                $('input#salutation ~ p.text-danger').html($('input#salutation').data('validation-message'));
                state = false;
            } else {
                $('input#salutation ~ p.text-danger').html('');
            }

            if (true === checkAgreement && '' == checkFirstname) {
                $('input#firstname ~ p.text-danger').html($('input#firstname').data('validation-message'));
                state = false;
            } else {
                $('input#firstname ~ p.text-danger').html('');
            }

            if (true === checkAgreement && '' == checkLastname) {
                $('input#lastname ~ p.text-danger').html($('input#lastname').data('validation-message'));
                state = false;
            } else {
                $('input#lastname ~ p.text-danger').html('');
            }

            if (true === checkAgreement && '' == checkStreet) {
                $('input#street ~ p.text-danger').html($('input#street').data('validation-message'));
                state = false;
            } else {
                $('input#street ~ p.text-danger').html('');
            }

            if (true === checkAgreement && '' == checkZipcode) {
                $('input#zipcode ~ p.text-danger').html($('input#zipcode').data('validation-message'));
                state = false;
            } else {
                $('input#zipcode ~ p.text-danger').html('');
            }

            if (true === checkAgreement && '' == checkCity) {
                $('input#city ~ p.text-danger').html($('input#city').data('validation-message'));
                state = false;
            } else {
                $('input#city ~ p.text-danger').html('');
            }
        }

        if ('cvForm'               === formName ||
            'quickcheckForm'       === formName ||
            'contactForm'          === formName ||
            'bookingForm'          === formName ||
            'contactBasisForm'     === formName ||
            'contactBasisTextForm' === formName) {
            switch (formName) {
                case 'quickcheckForm':
                case 'contactForm':
                case 'contactBasisForm':
                case 'cvForm':
                    //if ('' === fullname || '' === email || phone === '' || message === '') {
                        //doCheckPrivacy();
                    //}
                    doCheckPrivacy();
                    break;
                case 'contactBasisTextForm':
                    if ('' === fullname || '' === email || phone === '') {
                        doCheckPrivacy();
                    }
                    break;
                case 'bookingForm':
                    doCheckAgb();
                    doCheckPrivacy2();
                    break;
            }
        }

        /**
         * Prueft die Datenschutzbestimmungen
         */
        function doCheckPrivacy()
        {
            if (true !== $('#privacy').is(':checked')) {
                $('label[for="privacy"] ~ p.text-danger').html($('input#privacy').data('validation-message'));
                state = false;
            } else {
                $('label[for="privacy"] ~ p.text-danger').html('');
            }
        }

        /**
         * Prueft die Datenschutzbestimmungen im Buchungsformular
         */
        function doCheckPrivacy2()
        {
            if (true !== $('#privacy2').is(':checked')) {
                $('label[for="privacy2"] ~ p.text-danger').html($('input#privacy2').data('validation-message'));
                state = false;
            } else {
                $('label[for="privacy2"] ~ p.text-danger').html('');
            }
        }

        /**
         * Prueft die AGB
         */
        function doCheckAgb()
        {
            if ($('#agb').length) {
                if (true !== $('#agb').is(':checked')) {
                    $('label[for="agb"] ~ p.text-danger').html($('input#agb').data('validation-message'));
                    state = false;
                } else {
                    $('label[for="agb"] ~ p.text-danger').html('');
                }
            }
        }

        /**
         * Prüft den Rahmenvertrag
         */
        function doCheckContract()
        {
            if (true !== $('#rahmenvertrag').is(':checked')) {
                $('label[for="rahmenvertrag"] ~ p.text-danger').html($('input#rahmenvertrag').data('validation-message'));
                state = false;
            } else {
                $('label[for="rahmenvertrag"] ~ p.text-danger').html('');
            }
        }

        if ('' == dateOfBirth && 'adresseBoniForm' === formName) {
            $('input#dateOfBirth ~ p.text-danger').html($('input#dateOfBirth').data('validation-message'));
            state = false;
        } else {
            $('input#dateOfBirth ~ p.text-danger').html('');
        }

        if ('' == firstname && 'adresseBoniForm' === formName) {
            $('input#firstname ~ p.text-danger').html($('input#firstname').data('validation-message'));
            state = false;
        } else {
            $('input#firstname ~ p.text-danger').html('');
        }

        if ('' == lastname && 'adresseBoniForm' === formName) {
            $('input#lastname ~ p.text-danger').html($('input#lastname').data('validation-message'));
            state = false;
        } else {
            $('input#lastname ~ p.text-danger').html('');
        }

        if (formName !== 'bookingForm') {
            if ('' == phone && 'adresseBoniForm' !== formName) {
                $('input#phone ~ p.text-danger').html($('input#phone').data('validation-message'));
                state = false;
            } else {
                $('input#phone ~ p.text-danger').html('');
            }

            if ('' == zipcode && 'adresseBoniForm' === formName) {
                $('input#zipcode ~ p.text-danger').html($('input#zipcode').data('validation-message'));
                state = false;
            } else {
                $('input#zipcode ~ p.text-danger').html('');
            }

            if ('' == city && 'adresseBoniForm' === formName) {
                $('input#city ~ p.text-danger').html($('input#city').data('validation-message'));
                state = false;
            } else {
                $('input#city ~ p.text-danger').html('');
            }

            if ('' == street && 'adresseBoniForm' === formName) {
                $('input#street ~ p.text-danger').html($('input#street').data('validation-message'));
                state = false;
            } else {
                $('input#street ~ p.text-danger').html('');
            }

            if (false == isEmail(email) && 'adresseBoniForm' !== formName) {
                $('input#email ~ p.text-danger').html($('input#email').data('validation-message'));
                state = false;
            } else {
                $('input#email ~ p.text-danger').html('');
            }

            if ('' === fullname && 'adresseBoniForm' !== formName) {
                $('input#fullname ~ p.text-danger').html($('input#fullname').data('validation-message'));
                state = false;
            } else {
                $('input#fullname ~ p.text-danger').html('');
            }

            if ('' === message &&
                formName != 'contactBasisForm' &&
                'adresseBoniForm' !== formName &&
                'contactBasisTextForm' !== formName) {
                $('textarea#message ~ p.text-danger').html($('textarea#message').data('validation-message'));
                state = false;
            } else {
                $('textarea#message ~ p.text-danger').html('');
            }
        }

        if (true === state) {
            $.ajax({
                url: '/application/themes/cleardesign/mail/contact.php', // Url to which the request is send
                type: 'POST', // Type of request to be send, called as method
                data: new FormData(this), // Data sent to server, a set of key/value pairs (i.e. form fields and values)
                contentType: false, // The content type used when sending data to the server.
                cache: false, // To unable request pages to be cached
                processData: false, // To send DOMDocument or non processed data file it is set to false
                success: function (data) {
                    $('.success').removeClass('hidden');
                    if (true == data.state) {
                        // Rout to other site
                        window.location.href = outSite;
                        // Success message
                        $('.success').html('<div class="alert alert-success">');
                        $('.success > .alert-success').html('<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>');
                        $('.success > .alert-success').append('<strong>'+data.message+'</strong>');
                        $('.success > .alert-success').append('</div>');
                    } else {
                        // Error message
                        $('.success').html('<div class="alert alert-danger">');
                        $('.success > .alert-danger').html('<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>');
                        $('.success > .alert-danger').append('<strong>'+data.message+'</strong>');
                        $('.success > .alert-danger').append('</div>');
                    }
                    // Clear all fields
                    $('.contactForm, .cvForm, .quickcheckForm, .contactBasisForm, .adresseBoniForm, .bookingForm').trigger('reset');
                    $('p.text-danger').html();
                    resetValidationMessage();
                },
                error: function () {
                    $('.success').removeClass('hidden');
                    // Fail message
                    $('.success').html('<div class="alert alert-danger">');
                    $('.success > .alert-danger').html('<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>');
                    $('.success > .alert-danger').append("<strong>Entschuldigung " + firstName + ", Ihre Mail kann nicht versendet werden. Versuchen Sie es später!");
                    $('.success > .alert-danger').append('</div>');
                    //clear all fields
                    $('.contactForm, .cvForm, .quickcheckForm, .contactBasisForm, .adresseBoniForm, .bookingForm').trigger('reset');
                    resetValidationMessage();
                },
                dataType: 'json'
            });
        }

    });

    // VALIDATION OF CODE
    $('#doCodeCheck').on('click', function(event) {
        event.preventDefault();
        checkCodeAndChangeValues();
    });
    // END VALIDATION OF CODE

    /**
     * Ermittelt die Frachtkosten zzgl. 19% MwSt
     *
     * @param float total
     * @param int amountOfPieces
     * @param string shippingInfoField
     * @param float shippingSet
     * @param int shippingSet
     * @returns {number}
     */
    function getShippingCost(total, amountOfPieces, shippingInfoField, shippingCost, shippingSet) {
        let cost = 0;
        switch (shippingInfoField.toLowerCase()) {
            case 'standard':
                cost = total >= 357 ? 0 : shippingCost;
                break;
            case 'sperrgut':
            case 'spedition':
                cost = Math.ceil(amountOfPieces/shippingSet) * shippingCost;
                break;
            default:
                cost = 0;
        }

        return parseFloat(cost);
    }

    function checkCodeAndChangeValues() {
        var codeType = $('#formType').val();
        $.ajax({
            url: '/application/themes/cleardesign/mail/validation.php', // Url to which the request is send
            type: 'POST',
            data: {
                'codeType': codeType,
                'currencyIcon': $('#currencyIcon').val(),
                'code': $('#bookingCode').val(),
                'itemId': $('#itemId').val()
            },
            cache: false,
            success: function (data) {
                $('#validationInfoCol').show();
                var amountOfItems = $('#amountOfPersons').length > 0 ? $('#amountOfPersons').val() : $('#amountOfPieces').val();

                if (true == data.state) {
                    // Success message
                    $('#validationInfo').removeClass('rot-braun-strong').addClass('hellgruen-strong').html(data.sign+' '+formatThousands(data.value, 2));
                    if ('product' === codeType) {
                        $('#abzgl').html('abzgl. Warengutschein: '+data.sign+ ' '+formatThousands(data.value, 2)+' inkl. '+$('#tmpVat').val()+'% MwSt.<br />');
                    } else {
                        $('#abzgl').html('(abzgl. '+data.sign+ ' '+data.value+',00 Gutschein)');
                    }
                    $('.billingInfo').show();
                    $('.signInfo').text(data.sign);
                    $('.signInfo2').text($('#currencyIcon').val());
                    $('#codeValue').val(data.value);
                    $('.rabatt').text(formatThousands(data.value, 2)+',00');
                    $('#codeOperator').val(data.operator);
                    // Store bookingCode in hidden field to submit them
                    $('#validBookingCodeX').val($('#bookingCode').val());
                    $('.priceTotal').text(formatThousands($('#priceNew').val() * amountOfItems, 2));
                    let totalWithoutDiscount = $('#priceNew').val() * amountOfItems;
                    if (data.operator == '*') {
                        var total = $('#priceNew').val() * amountOfItems * (1-data.value/100);
                    } else {
                        var total = $('#priceNew').val() * amountOfItems - data.value;
                    }
                    total = total < 0 ? 0 : total;
                    total = Math.round(total*100)/100;

                    let shippingCost = 0;
                    if ('product' === codeType) {
                        shippingCost = getShippingCost(totalWithoutDiscount, amountOfItems, $('#tmpShipping').val(), $('#tmpShippingCost').val(), $('#tmpshippingSet').val());
                    }
                    total += shippingCost;

                    $('#shippingCost').html(formatThousands(shippingCost, 2));
                    $('.priceBillingTotal').html(formatThousands(total, 2));
                    $('#doCodeCheck, #bookingCode').attr('disabled', 'disabled');
                } else {
                    $('.billingInfo').show();
                    $('.priceTotal').text(formatThousands($('#priceNew').val() * amountOfItems, 2));
                    let total = $('#priceNew').val() * amountOfItems;
                    total = total < 0 ? 0 : total;

                    let shippingCost = 0
                    if ('product' === codeType) {
                        shippingCost = getShippingCost(total, amountOfItems, $('#tmpShipping').val(), $('#tmpShippingCost').val(), $('#tmpshippingSet').val());
                    }
                    total += shippingCost;
                    total = Math.round(total*100)/100;

                    $('#shippingCost').html(formatThousands(shippingCost, 2));
                    $('.priceBillingTotal').html(formatThousands(total, 2));
                    // Error message
                    $('#validationInfo').removeClass('hellgruen-strong').addClass('rot-braun-strong').html(data.message);
                }
            },
            error: function () {
                $('#validationInfoCol').show();
                // Fail message
                $('#validationInfo').addClass('rot-braun-strong').html('Serverfehler: Versuchen Sie es später!');
            },
            dataType: 'json'
        });
    }

    // CSV-UPLOAD
    $('.csvUploadForm, .csvProdUploadForm').submit(function(e) {
        e.preventDefault();

        var outSite = '' === $('#outSite').val() ? location.href : $('#outSite').val();
        var state   = true;
        var frmName = $(this).attr('name');
        var successClass = frmName === 'csvProdUploadForm' ? '.prod-csv-success' : '.csv-success';

        if (true === state) {
            $.ajax({
                xhr: function() {
                    let xhr = new window.XMLHttpRequest();
                    xhr.upload.addEventListener('progress', function(evt) {
                        if (evt.lengthComputable) {
                            let percentComplete = ((evt.loaded / evt.total) * 100);
                            $('.progress-bar').width(percentComplete + '%');
                            $('.progress-bar').html(percentComplete+'%');
                        }
                    }, false);
                    return xhr;
                },
                url: '/application/themes/cleardesign/mail/csv.php', // Url to which the request send
                type: 'POST', // Type of request to send, called as method
                data: new FormData(this), // Data sent to server, a set of key/value pairs (i.e. form fields and values)
                contentType: false, // The content type used when sending data to the server.
                cache: false, // To unable request pages to be cached
                processData: false, // To send DOMDocument or non processed data file it is set to false
                beforeSend: function() {
                    $('#uploadModal').remove();
                    $('body').append(`<div class="modal fade" id="uploadModal" tabindex="-1" role="dialog">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h4 class="modal-title">Dateiupload</h4>
                                        </div>
                                        <div class="modal-body text-center">
                                            <h3>Datei wird verarbeitet, bitte warten</h3>
                                            <svg class="server-processing" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                              <circle class="server-processing" cx="50" cy="50" r="25"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>`);
                    $('#uploadModal').modal();
                    $('.progress-bar').width('0%');
                },
                success: function (data) {
                    $('#uploadModal').modal('hide');
                    if (true == data.state) {
                        // Rout to other site
                        //window.location.href = outSite;
                        // Success message
                        $(successClass).html('<div class="alert alert-success">');
                        $(successClass + ' > .alert-success').html('<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>');
                        $(successClass + ' .alert-success').append('<strong>'+data.message+'</strong>');
                        $(successClass + ' > .alert-success').append('</div>');
                    } else {
                        // Error message
                        $(successClass).html('<div class="alert alert-danger">');
                        $(successClass + ' > .alert-danger').html('<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>');
                        $(successClass + ' > .alert-danger').append('<strong>'+data.message+'</strong>');
                        $(successClass + ' > .alert-danger').append('</div>');
                    }
                    // Clear all fields
                    $('.'+frmName).trigger('reset');
                    $('p.text-danger').html();
                    resetValidationMessage();
                },
                error: function () {
                    $('#uploadModal').modal('hide');
                    // Fail message
                    $(successClass).html('<div class="alert alert-danger">');
                    $(successClass + ' > .alert-danger').html('<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>');
                    $(successClass + ' > .alert-danger').append("<strong>Serverfehler: Versuchen Sie es später!</strong>");
                    $(successClass + ' > .alert-danger').append('</div>');
                    //clear all fields
                    $('.'+frmName).trigger('reset');
                    resetValidationMessage();
                },
                dataType: 'json'
            });
        }

    });

    // CSV-DOWNLOAD
    $('#doCsvExport').on('click', function(event) {
        event.preventDefault();
        window.document.location.href = 'application/themes/cleardesign/mail/csv.php?task=export';
    });

    // PRODUCT CSV-DOWNLOAD
    $('#doCsvProductExport').on('click', function(event) {
        event.preventDefault();
        window.document.location.href = 'application/themes/cleardesign/mail/csv.php?task=export&is_product_csv=1';
    });

    // NEWSLETER
    $('.newsletterForm').submit(function(e) {
        e.preventDefault();

        var email = $('input#email').val();
        var state = true;

        if (false == isEmail(email)) {
            $('.email-alert').html($('input#email').data('validation-message'));
            state = false;
        } else {
            $('.email-alert').html('');
        }

        if (true !== $('#privacy').is(':checked')) {
            $('label[for="privacy"] ~ p.text-danger').html($('input#privacy').data('validation-message'));
            state = false;
        } else {
            $('label[for="privacy"] ~ p.text-danger').html('');
        }

        if (true === state) {
            $.ajax({
                url: '/application/themes/cleardesign/mail/newsletter.php', // Url to which the request is send
                type: 'POST', // Type of request to be send, called as method
                data: new FormData(this), // Data sent to server, a set of key/value pairs (i.e. form fields and values)
                contentType: false, // The content type used when sending data to the server.
                cache: false, // To unable request pages to be cached
                processData: false, // To send DOMDocument or non processed data file it is set to false
                success: function (response) {
                    if (true == response.state) {
                        // Rout to other site
                        if (response.redirectUri != '') {
                            window.location.href = response.redirectUri;
                        }
                        // Success message
                        $('.success').html('<div class="alert alert-success">');
                        $('.success > .alert-success').html('<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>');
                        $('.success > .alert-success').append('<strong>'+response.message+'</strong>');
                        $('.success > .alert-success').append('</div>');
                    } else {
                        // Error message
                        $('.success').html('<div class="alert alert-danger">');
                        $('.success > .alert-danger').html('<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>');
                        $('.success > .alert-danger').append('<strong>'+response.message+'</strong>');
                        $('.success > .alert-danger').append('</div>');
                    }
                    // Clear all fields
                    $('.newsletterForm').trigger('reset');
                    $('p.text-danger').html();
                    resetValidationMessage();
                },
                error: function () {
                    // Fail message
                    $('.success').html('<div class="alert alert-danger">');
                    $('.success > .alert-danger').html('<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>');
                    $('.success > .alert-danger').append("<strong>Serverfehler: Versuchen Sie es später!</strong>");
                    $('.success > .alert-danger').append('</div>');
                    //clear all fields
                    $('.newsletterForm').trigger('reset');
                    resetValidationMessage();
                },
                dataType: 'json'
            });
        }

    });

    function resetValidationMessage() {
        $('p.text-danger').each(function() {
            $(this).html();
        });
    }

    function isEmail(email) {
        return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(email);
    }

    // Ermitelt die URL aus dem OG-Tag falls vorhanden ansonsten die aktuelle URL
    function getUrl() {
        return 0 === $('meta[property="og:url"]').length ? location.href : $('meta[property="og:url"]').attr('content');
    }

    // Facebook share
    $('.cleardesign-wrapper .fb-share, .cleardesign-wrapper .fb-share-one').click(function(event) {
        event.preventDefault();

        var sharer = "https://www.facebook.com/sharer/sharer.php?u=";
        window.open(sharer + encodeURIComponent(getUrl()),'sharer', 'width=626,height=436');
    });
    // Xing share
    $('.cleardesign-wrapper .xing-share, .cleardesign-wrapper .xing-share-one').click(function(event) {
        event.preventDefault();

        var sharer = "https://www.xing.com/spi/shares/new?url=";
        window.open(sharer + encodeURIComponent(getUrl()),'sharer', 'width=626,height=436');
    });
    // Twitter share
    $('.cleardesign-wrapper .twitter-share, .cleardesign-wrapper .twitter-share-one').click(function(event) {
        event.preventDefault();

        var sharer = "https://twitter.com/intent/tweet?text=Das ist interessant!&url=";
        window.open(sharer + encodeURIComponent(getUrl()),'sharer', 'width=626,height=436');
    });
    // LinkedIn share
    $('.cleardesign-wrapper .linkedin-share, .cleardesign-wrapper .linkedin-share-one').click(function(event) {
        event.preventDefault();

        var sharer = "https://www.linkedin.com/uas/connect/user-signin?session_redirect=";
        window.open(sharer + encodeURIComponent(getUrl()),'sharer', 'width=626,height=436');
    });
    // Instagram share
    $('.cleardesign-wrapper .instagram-share, .cleardesign-wrapper .instagram-share-one').click(function(event) {
        event.preventDefault();

        var sharer = "https://www.instagram.com/";
        window.open(sharer,'sharer', 'width=626,height=436');
    });
    // Email Forwarding
    let emailShareTitle  =  $('h1').html();
    let emailShareMailto = 'mailto:?subject='+emailShareTitle+'&body=Sehr%20empfehlenswerte%20Seite%3A%20';
    emailShareMailto = emailShareMailto+encodeURIComponent(getUrl());
    $('.cleardesign-wrapper .email-share').attr('href', emailShareMailto);

    // PRODUKT BESTELLFORMULAR
    // =========================
    $('#amountOfPieces').val(1); // Zurücksetzen der Stückzahl
    $('#validBookingCodeX, #bookingCode').val(''); // Zurücksetzen des Gutscheincodes bei refresh
    $('#isCompanyOrPrivate').filter('[value=private]').prop('checked', true); // Zurücksetzen RG für Privatperson

    $('#amountOfPieces').on('change', function() {
        // Wenn Gutscheincode vorhanden, dann prüfen und Werte nach Anzahl der Personen ändern!
        if ($('#bookingCode').val()) {
            checkCodeAndChangeValues();
        } else {
            let total = $('#priceNew').val() * $(this).val();
            let shippingCost = getShippingCost(total, $(this).val(), $('#tmpShipping').val(), $('#tmpShippingCost').val(), $('#tmpshippingSet').val());

            $('#shippingCost').html(formatThousands(shippingCost, 2));
            $('.priceTotal').html(formatThousands(total, 2));
            $('.billingInfo').show();
            $('.priceBillingTotal').html(formatThousands(total+shippingCost, 2));
        }

        $('#btnAmountOfPieces').html($(this).val());
    });

    $('input[name="delivery"]').on('change', function() {
        if (true === $(this).prop('checked')) {
            $('#deliveryFieldContainer').removeClass('hidden').addClass('show');
        } else {
            $('#deliveryFieldContainer').removeClass('show').addClass('hidden');
        }
    });

    $('.productForm').submit(function(e) {
        e.preventDefault();

        var outSite   = '' === $('#outSite').val() ? location.href : $('#outSite').val();
        var state     = true;
        var customerFirstname  = $('input#customerFirstname').val();
        var customerLastname   = $('input#customerLastname').val();
        var customerEmail      = $('input#customerEmail').val();
        var isCompanyOrPrivate  = $('input[name="isCompanyOrPrivate"]:checked').val();
        var companyname         = $('input#companyname').val();
        var street              = $('input#street').val();
        var housenumber         = $('input#housenumber').val();
        var zipcode             = $('input#zipcode').val();
        var city                = $('input#city').val();

        var deliveryFullname    = $('input#deliveryFullname').val();
        var deliveryStreet      = $('input#deliveryStreet').val();
        var deliveryHousenumber = $('input#deliveryHousenumber').val();
        var deliveryZipcode     = $('input#deliveryZipcode').val();
        var deliveryCity        = $('input#deliveryCity').val();

        if (false == isEmail(customerEmail)) {
            $('input#customerEmail ~ p.text-danger').html($('input#customerEmail').data('validation-message'));
            state = false;
        } else {
            $('input#customerEmail ~ p.text-danger').html('');
        }

        if ($('#delivery').is(':checked')) {
            if ('' == deliveryFullname) {
                $('input#deliveryFullname ~ p.text-danger').html($('input#deliveryFullname').data('validation-message'));
                state = false;
            } else {
                $('input#deliveryFullname ~ p.text-danger').html('');
            }

            if ('' == deliveryStreet) {
                $('input#deliveryStreet ~ p.text-danger').html($('input#deliveryStreet').data('validation-message'));
                state = false;
            } else {
                $('input#deliveryStreet ~ p.text-danger').html('');
            }

            if ('' == deliveryHousenumber) {
                $('input#deliveryHousenumber ~ p.text-danger').html($('input#deliveryHousenumber').data('validation-message'));
                state = false;
            } else {
                $('input#deliveryHousenumber ~ p.text-danger').html('');
            }

            if ('' == deliveryZipcode) {
                $('input#deliveryZipcode ~ p.text-danger').html($('input#deliveryZipcode').data('validation-message'));
                state = false;
            } else {
                $('input#deliveryZipcode ~ p.text-danger').html('');
            }

            if ('' == deliveryCity) {
                $('input#deliveryCity ~ p.text-danger').html($('input#deliveryCity').data('validation-message'));
                state = false;
            } else {
                $('input#deliveryCity ~ p.text-danger').html('');
            }
        } else {
            $('input#deliveryFullname ~ p.text-danger').html('');
            $('input#deliveryStreet ~ p.text-danger').html('');
            $('input#deliveryHousenumber ~ p.text-danger').html('');
            $('input#deliveryZipcode ~ p.text-danger').html('');
            $('input#deliveryCity ~ p.text-danger').html('');
        }

        if ('company' === isCompanyOrPrivate && $('#agb').length) {
            if ('' == companyname && $('#agb').length) {
                $('input#companyname ~ p.text-danger').html($('input#companyname').data('validation-message'));
                state = false;
            } else {
                $('input#companyname ~ p.text-danger').html('');
            }
        }

        if ('private' === isCompanyOrPrivate) {
            if ('' == customerFirstname && $('#agb').length) {
                $('input#customerFirstname ~ p.text-danger').html($('input#customerFirstname').data('validation-message'));
                state = false;
            } else {
                $('input#customerFirstname ~ p.text-danger').html('');
            }

            if ('' == customerLastname && $('#agb').length) {
                $('input#customerLastname ~ p.text-danger').html($('input#customerLastname').data('validation-message'));
                state = false;
            } else {
                $('input#customerLastname ~ p.text-danger').html('');
            }
        }

       if ('' == street && $('#agb').length) {
           $('input#street ~ p.text-danger').html($('input#street').data('validation-message'));
           state = false;
       } else {
           $('input#street ~ p.text-danger').html('');
       }

        if ('' == housenumber && $('#agb').length) {
            $('input#housenumber ~ p.text-danger').html($('input#housenumber').data('validation-message'));
            state = false;
        } else {
            $('input#housenumber ~ p.text-danger').html('');
        }

        if ('' == zipcode && $('#agb').length) {
            $('input#zipcode ~ p.text-danger').html($('input#zipcode').data('validation-message'));
            state = false;
        } else {
            $('input#zipcode ~ p.text-danger').html('');
        }

        if ('' == city && $('#agb').length) {
            $('input#city ~ p.text-danger').html($('input#city').data('validation-message'));
            state = false;
        } else {
            $('input#city ~ p.text-danger').html('');
        }

        /**
         * Prüft die Datenschutzbestimmungen im Buchungsformular
         */
        function doCheckPrivacy2()
        {
            if (true !== $('#privacy2').is(':checked')) {
                $('label[for="privacy2"] ~ p.text-danger').html($('input#privacy2').data('validation-message'));
                state = false;
            } else {
                $('label[for="privacy2"] ~ p.text-danger').html('');
            }
        }

        /**
         * Prüft die AGB
         */
        function doCheckAgb()
        {
            if ($('#agb').length) {
                if (true !== $('#agb').is(':checked')) {
                    $('label[for="agb"] ~ p.text-danger').html($('input#agb').data('validation-message'));
                    state = false;
                } else {
                    $('label[for="agb"] ~ p.text-danger').html('');
                }
            }
        }

        doCheckAgb();
        doCheckPrivacy2();

        if (true === state) {
            $.ajax({
                url: '/application/themes/cleardesign/mail/order.php', // Url to which the request is send
                type: 'POST', // Type of request to be send, called as method
                data: new FormData(this), // Data sent to server, a set of key/value pairs (i.e. form fields and values)
                contentType: false, // The content type used when sending data to the server.
                cache: false, // To unable request pages to be cached
                processData: false, // To send DOMDocument or non processed data file it is set to false
                success: function (data) {
                    $('.success').removeClass('hidden');
                    if (true == data.state) {
                        // Rout to other site
                        window.location.href = outSite;
                        // Success message
                        $('.success').html('<div class="alert alert-success">');
                        $('.success > .alert-success').html('<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>');
                        $('.success > .alert-success').append('<strong>'+data.message+'</strong>');
                        $('.success > .alert-success').append('</div>');
                    } else {
                        // Error message
                        $('.success').html('<div class="alert alert-danger">');
                        $('.success > .alert-danger').html('<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>');
                        $('.success > .alert-danger').append('<strong>'+data.message+'</strong>');
                        $('.success > .alert-danger').append('</div>');
                    }
                    // Clear all fields
                    $('.productForm').trigger('reset');
                    $('p.text-danger').html();
                    resetValidationMessage();
                },
                error: function () {
                    $('.success').removeClass('hidden');
                    // Fail message
                    $('.success').html('<div class="alert alert-danger">');
                    $('.success > .alert-danger').html('<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>');
                    $('.success > .alert-danger').append("<strong>Entschuldigung " + customerFirstname + " " +customerLastname+", Ihre Mail kann nicht versendet werden. Versuchen Sie es später!");
                    $('.success > .alert-danger').append('</div>');
                    //clear all fields
                    $('.productForm').trigger('reset');
                    resetValidationMessage();
                },
                dataType: 'json'
            });
        }
    });// /productForm

});