$(function () {
    'use strict';

    /**
     * Prüft ob alle relevanten OG-Metatags vorhanden sind
     *
     * @return {boolean}
     */
    function checkOgTags() {
        if (0 === $('meta[property="og:url"]').length)          { return false; }
        if (0 === $('meta[property="og:title"]').length)        { return false; }
        if (0 === $('meta[property="og:description"]').length)  { return false; }
        if (0 === $('meta[property="og:image"]').length)        { return false; }

        return true;
    }

    // Versteckt die Socialmedia Buttons wenn die OG-Metaangaben fehlen
    if (false === checkOgTags()) {
        $('.fb-share, .xing-share, .twitter-share, .instagram-share, .instagram-share-one, .linkedin-share, .email-share, .fb-share-one, .xing-share-one, .twitter-share-one, .linkedin-share-one, .email-share-one').hide();
    }

    // Versteckt den Whatsapp Button bei der Desktopversion oder fehlenden OG-Metatags!
    if (1 === $('html.no-touch').length || false === checkOgTags()) {
        $('a[class~="whatsapp-share"], a[class~="whatsapp-share-one"]').addClass('hidden');
    } else {
        var ogUrl = $('meta[property="og:url"]').attr('content');
        var href = encodeURI('*Schau Dir mal diese Seite an:* ' + ogUrl);
        $('a[class~="whatsapp-share"], a[class~="whatsapp-share-one"]').attr('href', 'whatsapp://send?text=' + href);
    }

    /* Preloader */
    $(window).load(function () {
        // will first fade out the loading animation
        $('#status').fadeOut('slow');
        // will fade out the whole DIV that covers the website.
        $('#preloader').delay(500).fadeOut('slow').remove();
    })

    /* Show Menu on Book */
    $(window).bind('scroll', function () {
        /* Scroll to top */
        if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
        } else {
            $('.scrollup').fadeOut();
        }
    });

    /* Scroll to top */
    $('.scrollup')
        .click(function (event) {
            event.preventDefault();
            $('html, body').animate({scrollTop: 0}, 600);
        })
        .hover(function () {
            $(this).stop().animate({'opacity': 1}, 600, 'linear');
        }, function () {
            $(this).stop().animate({'opacity': 0.3}, 600, 'linear');
        });

    /*$('body').scrollspy({
        target: '.navbar-default',
        offset: 80
    })*/

    /*$(document).ready(function () {
        if (typeof owlCarousel === 'function') {
            $('#customer-reviews, .reviews').owlCarousel({
                autoPlay: true,
                autoplayTimeout: 8000,
                navigation: false, // Show next and prev buttons
                slideSpeed: 8000,
                paginationSpeed: 800,
                singleItem: true
            });
        }
    });*/

    /* WOW JS */
    // new WOW().init();

    /* Popover */
    $('a[data-popover]').click(function (e) {
        $('#loginBox').toggle('fast', function () {
            $(this).addClass('is-active');
            if ($('#loginBox').is(':hidden')) {
                $(this).parent().removeClass('active');
            } else {
                $(this).parent().addClass('active');
            }
        });
    });

    /* Thumbs */
    /*$('a[href="#logoContentDropdown"], #logoaction').click(function(event) {
        if ($('#logoContentDropdown').is(':hidden')) {
            $('a[href="#logoContentDropdown"]').parent().addClass('active');
        } else {
            $('a[href="#logoContentDropdown"]').parent().removeClass('active');
        }
    });*/

    /* Scrollen zu den jeweiligen Ankerpunkten */
    $('a[href=#formX], a[href^=#kapitel], a[href^=#kontakt], a[href^=#frage]').on('click', function (e) {
        e.preventDefault();

        if ($(this).attr('data-selected-tab-id')) {
            activaTab($(this).attr('data-selected-tab-id'));
        }

        var href = $(this).attr('href');
        $('html, body').animate({scrollTop: $(href).offset().top - 70}, 'slow');
    });

    function activaTab(tab){
        $('.nav-tabs a[href="#' + tab + '"]').tab('show');
    };

    /* Dropdown */
    $('.drop-down-arrow').on('click', function(event) {
        event.preventDefault();

        if ($('#'+$(this).data('dropdown-id')).is(':visible') === true) {
            $(this).children().removeClass('fa-arrow-circle-up');
            $(this).children().addClass('fa-arrow-circle-down');
            $('#'+$(this).data('dropdown-id')).hide();
        } else {
            $(this).children().removeClass('fa-arrow-circle-down');
            $(this).children().addClass('fa-arrow-circle-up');
            $('#'+$(this).data('dropdown-id')).show();
        }
    });

    $('.drop-down').on('click', function(event) {
        event.preventDefault();

        if ($('#'+$(this).data('dropdown-id')).is(':visible') === true) {
            $(this).removeClass('btn-display-yes');
            $(this).children().removeClass('fa-align-right');
            $(this).children().addClass('fa-align-left');
            $('#'+$(this).data('dropdown-id')).hide();
        } else {
            $(this).addClass('btn-display-yes');
            $(this).children().removeClass('fa-align-left');
            $(this).children().addClass('fa-align-right');
            $('#'+$(this).data('dropdown-id')).show();
        }
    });

    /* Toogle Nav-Tabs */
    $('.tt').click(function() {
        $('.tab-content').find('.tab-pane').not(':eq('+$(this).index()+')').hide('slow');
        $('.tab-content').find('.tab-pane').eq($(this).index()).slideToggle({'duration': 'slow', 'direction': "up"}).addClass('active');
    });

    /* Card flip */
    $('.card, .do-turn').on('click', function(event) {
        event.preventDefault();

        var cardId = $(this).data('cardid');

        $('#'+cardId).toggleClass('is-flipped');
        $('.'+cardId+'.card-info-text-front').toggle();
        $('.'+cardId+'.card-info-text-back').toggle();
    });

    /* Link on Tablerows */
    $('tr[data-href]').on({
        click: function() {
            document.location = $(this).data('href');
        }, touchstart: function() {
            document.location = $(this).data('href');
        }
    });

    /* Variant change */
    $('#variantCheck').on({
        change: function() {
            document.location = $(this).val();
        }, changedTouches: function() {
            document.location = $(this).val();
        }
    });

    /* Bildselector */
    $('.cleardesign-wrapper .thump-select').on({
        click: function(event) {
            event.preventDefault();
            $('.cleardesign-wrapper .big-image').attr('src', $(this).attr('href'));
        }
    });

    $('.cleardesign-wrapper .shippingInfo').on('click', function(event) {
        event.preventDefault();
        $('#shippingModal').remove();
        $.get('/application/themes/cleardesign/ajax/shippinginfo.html', function(html) {
            $('body').append(`<div class="modal fade" id="shippingModal" tabindex="-1" role="dialog">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">Liefer- und Versandkosten</h4>
                        </div>
                        <div class="modal-body">
                        ${html}
                        </div>
                    </div>
                </div>
            </div>`);
            $('#shippingModal').modal();
        });
    });
});